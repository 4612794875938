import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"

const ComponentName = ({data}) => {
  const {content} = data.blog

  return <Layout>
    <section className="blog-template">
      <div className="section-center">
        <article className="blog-content">
          <ReactMarkdown source={content}/>
          </article>
          <Link to="/blog" className="btn center-btn">
            Articles
          </Link>
      </div>
    </section>
  </Layout>
}


export const query = graphql`
  query GetSinglearticle($slug: String) {
    blog: strapiArticles(slug: { eq: $slug }) {
      content

    }
  }
`

export default ComponentName
